<template>
  <div>
    <v-card>
      <v-container>
        <v-layout wrap>
          <v-flex
            v-if="fieldType === fieldTypeObj.IMAGE"
            xs12
            md12
          >
            <div>
              <img
                id="template-img-id"
                :src="
                  imageFieldData !== null && imageFieldData !== ''
                    ? imageFieldData
                    : noImg
                "
                class="banner-img-class img-responsive"
                alt="banner-img"
              >
              <input
                id="upload-template-img"
                style="display: none;"
                type="file"
                hidden
                @change="onChangeTemplateImage($event)">
            </div>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions>
        <v-spacer/>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="templateItemSelect && templateItemSelect.id"
              class="mx-1"
              color="warning darken-1"
              v-on="on"
              @click="onRemoveTemplateItem()"
            >
              <v-icon>mdi-sync</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('common.reset') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mx-1"
              color="success darken-1"
              v-on="on"
              @click="onConfirm($event)"
            >
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('common.save') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mx-1"
              color="error darken-1"
              v-on="on"
              @click="onCloseModal()"
            >
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('common.close') }}</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import $ from 'jquery'
import ToastType from 'enum/toastType'
import PrintTemplateItemType from 'enum/printTemplateItemType'
import functionUtils from 'utils/functionUtils'
import axios from 'axios'
export default {
  data () {
    return {
      isShowModal: false,
      fieldType: 1,
      fieldTypeObj: {
        IMAGE: PrintTemplateItemType.IMAGE,
        TEXT: PrintTemplateItemType.TEXT
      },
      fieldName: null,
      itemKind: null,
      itemType: null,
      templateItemName: null,
      // image field data
      noImg: require('@/assets/noimage.png'),
      imageFieldData: null,
      bkgndImageSelectedFile: null,
      media_server: {
        domain: '',
        token: ''
      },
      templateId: null,
      templateItemSelect: null
    }
  },
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'GET_TOKEN_MEDIA_SERVER_DATA'
    ])
  },
  watch: {
    GET_TOKEN_MEDIA_SERVER_DATA: function () {
      this.media_server.token = this.GET_TOKEN_MEDIA_SERVER_DATA.data.token
      this.media_server.domain = this.GET_TOKEN_MEDIA_SERVER_DATA.data.domain
    }
  },
  mounted () {
    $(function () {
      $('#template-img-id').on('click', function () {
        // this.refreshTemplateImg()
        $('#upload-template-img').click()
      })
    })
    this.GET_TOKEN_MEDIA_SERVER()
  },
  methods: {
    /**
     * Confirm
     */
    onConfirm: function (event) {
      event.preventDefault()
      let validateResult = this.validateData()
      if (validateResult === 'OK') {
        this.onSave()
      } else {
        this.ON_SHOW_TOAST({
          'message': validateResult,
          'styleType': ToastType.ERROR
        })
      }
    },
    /**
     * Show modal
     */
    onShowModal: function (templateItem, templateId) {
      if (!functionUtils.isEmptyString(templateItem)) {
        this.templateItemSelect = templateItem
        this.fieldType = templateItem && templateItem.itemType
        this.fieldName = templateItem && templateItem.fieldName
        this.itemKind = templateItem && templateItem.itemKind
        this.itemType = templateItem && templateItem.itemType
        this.templateItemName = templateItem && templateItem.text
        this.imageFieldData = templateItem && templateItem.value
      } else {
        this.imageFieldData = null
      }
      this.templateId = templateId
      this.isShowModal = true
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
      this.$emit('onCloseComponent')
    },
    validateData: function () {
      let result = 'OK'
      if (this.fieldType === this.fieldTypeObj.IMAGE) {
        if (functionUtils.isEmptyString(this.imageFieldData)) {
          result = this.$t('common.image')
        }
      }
      return result
    },
    onSave: async function () {
      let data = {
        itemKind: this.itemKind,
        itemType: this.itemType,
        name: this.templateItemName,
        templateId: this.templateId
      }
      if (this.fieldType === this.fieldTypeObj.IMAGE) {
        if (this.bkgndImageSelectedFile) {
          await this.uploadBannerImage(this.bkgndImageSelectedFile)
        }
        data['value'] = this.imageFieldData
      }
      this.POST_PRINT_TEMPLATE_ITEM_CONFIG(data).then(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.isShowModal = false
          this.$emit('callbackTemplateConfigItem')
        }.bind(this)
      ).catch(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('login.somethingWentWrong'),
            'styleType': ToastType.ERROR
          })
        }
      )
    },
    refreshTemplateImg: function () {
      let inputForm = $('#upload-template-img')
      inputForm.val('')
    },
    onHandleEmitUpdateTemplate: function () {
      this.$emit('emitUpdateTemplateItemImage', { 'itemKind': this.itemKind, 'imageUrl': this.imageFieldData })
    },
    onChangeTemplateImage: function (e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.bkgndImageSelectedFile = files[0]
      this.imageFieldData = URL.createObjectURL(files[0])
      this.onHandleEmitUpdateTemplate()
    },
    /**
     * Upload advertisement image
     */
    uploadBannerImage: async function (file) {
      let formData = new FormData()
      formData.append('file', file)
      formData.append('directory', 'eVoucher/printVoucherTemplateConfig')
      formData.append('forceOverride', true)
      await axios({
        method: 'POST',
        timeout: 6000,
        url: this.media_server.domain + '/api/v1/upload/',
        data: formData,
        headers: { Authorization: `JWT ${this.media_server.token}` }
      }).then(
        async function (res) {
          let data = res.data
          let imageUrl = data.domain + data.url
          this.imageFieldData = imageUrl
          this.bkgndImageSelectedFile = null
        }.bind(this)
      ).catch(
        async function () {
          this.imageFieldData = null
        }.bind(this)
      )
    },
    onRemoveTemplateItem: function () {
      this.$emit('onRemoveTemplateItem', this.templateItemSelect)
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'POST_PRINT_TEMPLATE_ITEM_CONFIG',
      'GET_TOKEN_MEDIA_SERVER'
    ])
  }
}

</script>

<style lang="scss" scoped>
  .modal {
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  .v-card {
    margin-bottom: 0px !important;
    margin-top: 21px !important;
  }
  .v-btn {
    min-width: 0;
  }
  .banner-img-class {
    width: 200px;
    cursor: pointer;
    margin: auto;
  }
</style>
